@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body, html {
  height: 100%;
  overflow: hidden;
}

/* Styles pour le Header */
.header {
  position: sticky;
  top: 0;
  z-index: 10;
  /* Ajoutez d'autres styles selon vos besoins */
}

/* Styles pour le Sidebar */
.sidebar {
  width: 250px; /* ou la largeur que vous préférez */
  overflow-y: auto;
  /* Ajoutez d'autres styles selon vos besoins */
}

/* Styles pour le contenu principal */
main {
  padding: 20px;
  /* Autres styles selon vos besoins */
}
